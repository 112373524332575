import CookiesEuBanner from 'cookies-eu-banner'
import EventEmitter from 'events'

export default class Cookizi extends CookiesEuBanner {
  constructor(launchFunction, waitAccept) {
    super(launchFunction, waitAccept)

    this.sideBar = false
    this.events = new EventEmitter()

    // Set cookies
    this.setCookie('navigationConsent', document.cookie.match(new RegExp('(^| )navigationConsent=([^;]+)')) ? document.cookie.match(new RegExp('(^| )navigationConsent=([^;]+)'))[2] : 0)
    this.setCookie('statisticsConsent', document.cookie.match(new RegExp('(^| )statisticsConsent=([^;]+)')) ? document.cookie.match(new RegExp('(^| )statisticsConsent=([^;]+)'))[2] : 0)
    this.setCookie('socialConsent', document.cookie.match(new RegExp('(^| )socialConsent=([^;]+)')) ? document.cookie.match(new RegExp('(^| )socialConsent=([^;]+)'))[2] : 0)
    
    // Init switches
    this.switches = {
      'navigation': document.cookie.match(new RegExp('(^| )navigationConsent=([^;]+)'))[2],
      'statistics': document.cookie.match(new RegExp('(^| )statisticsConsent=([^;]+)'))[2],
      'social': document.cookie.match(new RegExp('(^| )socialConsent=([^;]+)'))[2]
    }

    // Define cookies ton delete
    this.cookiesName = {
      'navigation':  [],
      'statistics': ['__utma', '__utmb', '__utmc', '__utmt', '__utmv', '__utmz', '_ga', '_gat', '_gid'],
      'social': []
    }

    // Refuse all cookies
    document.querySelector('#cookies-eu-reject').addEventListener('click', event => {
      this.refuseCookies()
    })

    // Accept all cookies
    document.querySelector('#cookies-eu-accept').addEventListener('click', event => {
      this.acceptCookies()
    })

    this.initSidebar()
  }

  update() {
    for (const key in this.switches) {
      if(this.switches[key] == 0) {
        this.deleteCookies(key)
      } else if(key == 'statistics') {
        this.events.emit('statisticsOn')
      }
    }
  }

  refuseCookies() {
    // Off cookies
    this.setCookie('navigationConsent', 0)
    this.setCookie('statisticsConsent', 0)
    this.setCookie('socialConsent', 0)

    // Off switches
    this.switches = {
      'navigation': document.cookie.match(new RegExp('(^| )navigationConsent=([^;]+)'))[2],
      'statistics': document.cookie.match(new RegExp('(^| )statisticsConsent=([^;]+)'))[2],
      'social': document.cookie.match(new RegExp('(^| )socialConsent=([^;]+)'))[2]
    }

    // Update cookies
    this.update()
  }

  acceptCookies() {
    // On cookies
    this.setCookie('navigationConsent', 1)
    this.setCookie('statisticsConsent', 1)
    this.setCookie('socialConsent', 1)

    // On switches
    this.switches = {
      'navigation': document.cookie.match(new RegExp('(^| )navigationConsent=([^;]+)'))[2],
      'statistics': document.cookie.match(new RegExp('(^| )statisticsConsent=([^;]+)'))[2],
      'social': document.cookie.match(new RegExp('(^| )socialConsent=([^;]+)'))[2]
    }

    // Update cookies
    this.update()
  }
  
  deleteCookies(key) {
    this.cookiesName[key].map(cookieName => {
      this.deleteCookie(cookieName);
    })
  }

  initSidebar() {
    // Get sidebar
    this.sideBar = document.querySelector('.m-cookie-sidebar')
    
    for (const iziSwitch of document.querySelectorAll('.m-cookie-sidebar__switcher')) {
      // Event on switch
      iziSwitch.addEventListener('click', event => {
        event.target.dataset.value = event.target.dataset.value == 0 ? 1 : 0
      })
    }

    // Update cookies
    this.update()

    // Sidebar Show event
    document.querySelector('#cookies-eu-preferences').addEventListener('click', event => {
      event.preventDefault()
      this.showSidebar()
    })

    // Sidebar Hide event
    this.sideBar.querySelector('.m-cookie-sidebar__mask').addEventListener('click', event => {
      event.preventDefault()
      this.hideSidebar()
    })

    // Sidebar Register event
    this.sideBar.querySelector('#cookies-register').addEventListener('click', event => {
      event.preventDefault()
      this.registerSidebar()
      this.hideSidebar()
    })
  }

  showSidebar() {
    this.updateSidebar()
    this.sideBar.classList.add('m-cookie-sidebar--shown')
  }

  hideSidebar() {
    this.sideBar.classList.remove('m-cookie-sidebar--shown')
  }

  updateSidebar() {
    // Update values to display current real values on open (when user didn't save)
    for (const key in this.switches) {
      document.querySelector(`.m-cookie-sidebar__switcher[data-switch="${key}"]`).dataset.value = this.switches[key]
    }
  }

  registerSidebar() {
    // Register values
    for (const key in this.switches) {
      this.switches[key] = document.querySelector(`.m-cookie-sidebar__switcher[data-switch="${key}"]`).dataset.value
      this.setCookie(`${key}Consent`, this.switches[key])
    }  

    // Update cookies
    this.update()
  }
}

// cookie.setCookie('salu', 'sava')
// cookie.hasConsent()
// cookie.isBot()
// cookie.isToTrack()
// cookie.deleteTrackingCookies()
// cookie.deleteCookie('salu')
// cookie.showBanner()