require('custom-event-polyfill')

import '@js/vendor.js'
import '@scss/main.scss'

import fullpage from 'fullpage.js'
import Popbox from '@js/vendor/popbox/popbox.js'
import ScrollReveal from 'scrollreveal'
import SmoothScroll from 'smooth-scroll'
import Cookizi from './Cookizi'


document.addEventListener('DOMContentLoaded', function() {

    document.launchedTagManager = false

    // Cookizi
    const cookizi = new Cookizi(() => {})
    
    cookizi.events.on('statisticsOn', () => {
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-WZCSWCP');
    })

    // Footer Preferences trigger
    document.querySelector('.o-footer__preferences').addEventListener('click', event => {
        event.preventDefault()
        cookizi.showSidebar()
    })
    
    // Menu trigger
    const burger = document.querySelector('.a-burger')
    burger.addEventListener('click', () => {
        document.querySelector('.o-body').classList.toggle('o-body--menu-on')
    })
    // Menu close
    document.querySelector('.o-menu__mask').addEventListener('click', () => {
        document.querySelector('.o-body').classList.remove('o-body--menu-on')
    })

    // Define $hyt
    const $ = jQuery

    // Fullpage
    if(document.querySelector('#fullpage') && ($(window).width() > 767) && ($(window).height() > 600))  {
        const full = new fullpage('#fullpage', {
            licenseKey: 'OPEN-SOURCE-GPLV3-LICENSE',
            scrollBar : true,
            easingcss3 : 'cubic-bezier(0.165, 0.84, 0.44, 1)',
            navigation : true,
            fitToSection : true,
            slidesNavigation : false,
            normalScrollElements : '.o-footer',
            onLeave: function(origin, destination){
                $('#fp-nav ul li a span, .fp-slidesNav ul li a span').css('background', destination.item.getAttribute('data-color'))
                if(destination.index >= 2) {
                    document.querySelector('.o-body').classList.add('o-body--top')
                }else{
                    document.querySelector('.o-body').classList.remove('o-body--top')
                }
            }
        })
        // Destroy on popbox opening
        document.addEventListener('popbox_opening', () => {
            full.setMouseWheelScrolling(false);
            document.body.style.overflow = 'hidden'
        })
        document.addEventListener('popbox_closing', () => {
            full.setMouseWheelScrolling(true);
            document.body.style.overflow = 'visible'
        })
    }

    // ScrollReveal
    if(($(window).width() > 485))  {
        window.sr = ScrollReveal();
        sr.reveal('.bulle-1', { reset: true, delay: 100, duration: 1200, opacity: 0, origin: 'top', easing  : 'cubic-bezier(0.175, 0.885, 0.32, 1.275)', scale : 0.6, distance : '20px' })
        sr.reveal('.bulle-2', { reset: true, delay: 500, duration: 1000, opacity: 0, origin: 'bottom', easing  : 'cubic-bezier(0.215, 0.61, 0.355, 1)', scale : 0.9, distance : '20px' })
        sr.reveal('.bulle-3', { reset: true, delay: 400, duration: 1200, opacity: 0, origin: 'right', easing  : 'cubic-bezier(0.23, 1, 0.32, 1)', scale : 0, distance : '20px' })
        sr.reveal('.bulle-4', { reset: true, delay: 100, duration: 1000, opacity: 0, origin: 'left', easing  : 'cubic-bezier(0.23, 1, 0.32, 1)', scale : 0, distance : '30px' })
    }

    var popbox = new Popbox({
        // blur:true,
        overlay:true
    })
    
    
    if(document.querySelector("#mpb-download-stick .messages")) {
        popbox.open('mpb-download-stick')
    }
    if(document.querySelector("#mpb-contact .messages")) {
        popbox.open('mpb-contact')
    }
    if(document.querySelector("#mpb-download .messages")) {
        popbox.open('mpb-download')
    }
    if(document.querySelector("#mpb-newsletter .messages")) {
        popbox.open('mpb-newsletter')
    }  

    // Smooth scroll
    if(document.querySelector('.anchor')) {
        const scroll = new SmoothScroll('.anchor', {
            easing: 'easeInOutQuart',
            speed: 600
        })
    }

})